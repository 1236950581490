<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="名稱" prop="title" >
        <a-input v-model="form.title" placeholder="请输入名稱" />
      </a-form-model-item>
      <a-form-model-item label="兴趣标签" prop="tagId" >
        <a-select v-model="form.tagId" placeholder="请选择兴趣标签">
          <a-select-option v-for="(tag, index) in tagList" :value='tag.id'>{{ tag.name }}</a-select-option>
        </a-select>
      </a-form-model-item>


      <a-form-model-item label="封面" prop="cover" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="form.cover" :src="form.cover" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M </span>
      </a-form-model-item>

      <a-form-model-item label="背景图" prop="background" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest2'
          :beforeUpload="beforeUpload2"
          @change="handleChange2"
        >
          <img v-if="form.background" :src="form.background" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading2 ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M </span>
      </a-form-model-item>

      <a-form-model-item label="简介" prop="intro" >
        <a-input v-model="form.intro" placeholder="请输入简介" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getVideoTopic, addVideoTopic, updateVideoTopic, getTagList} from '@/api/platform/videoTopic'
import request from "@/utils/request";
import UploadFileToOSS from "@/utils/upload-file-to-oss";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      uploadLoading:false,
      uploadLoading2:false,
      formTitle: '',
      tagList: [],
      // 表单参数
      form: {
        id: null,

        tagId: null,

        cover: null,

        background: null,

        title: null,

        intro: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        tagId: [
          { required: true, message: '兴趣标签编号不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.cover = info.file.originFileObj.ossUrl;
      }
    },

    fileUploadRequest2(fileInfo) {
      this.uploadLoading2 = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload2: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange2(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading2 = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading2 = false;
        this.form.background = info.file.originFileObj.ossUrl;
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        tagId: null,
        cover: null,
        background: null,
        title: null,
        intro: null,
        createTime: null,
        remark: null,
      }
     /* this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'

      request({
        url: '/platform/interest-tag/list',
        method: 'get'
      }).then(response => {
        this.tagList = response.data;
      })

    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {

      request({
        url: '/platform/interest-tag/list',
        method: 'get'
      }).then(response => {
        this.tagList = response.data;
        this.reset()
        this.formType = 2
        const id = row ? row.id : ids
        getVideoTopic({"id":id}).then(response => {
          this.form = response.data
          this.open = true
          this.formTitle = '修改'
        })
      })


    },
    /** 提交按钮 */
    submitForm: function () {
      let that = this;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVideoTopic(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addVideoTopic(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
