import request from '@/utils/request'


// 查询视频-话题列表
export function listVideoTopic(query) {
  return request({
    url: '/platform/video-topic/list',
    method: 'get',
    params: query
  })
}

// 查询视频-话题分页
export function pageVideoTopic(query) {
  return request({
    url: '/platform/video-topic/page',
    method: 'get',
    params: query
  })
}

// 查询视频-话题详细
export function getVideoTopic(data) {
  return request({
    url: '/platform/video-topic/detail',
    method: 'get',
    params: data
  })
}

export function getTagList() {
  return request({
    url: '/platform/interest-tag/list',
    method: 'get',
    params: {}
  })
}

// 新增视频-话题
export function addVideoTopic(data) {
  return request({
    url: '/platform/video-topic/add',
    method: 'post',
    data: data
  })
}

// 修改视频-话题
export function updateVideoTopic(data) {
  return request({
    url: '/platform/video-topic/edit',
    method: 'post',
    data: data
  })
}

// 删除视频-话题
export function delVideoTopic(data) {
  return request({
    url: '/platform/video-topic/delete',
    method: 'post',
    data: data
  })
}

